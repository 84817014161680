import React, { Component } from 'react'

class DescriptionCard extends Component {
    render() {
        return (
            <div className='one_half'>
                <figure className='alignleft'></figure>
                <div>
                    <h3 style={{ textAlign: 'center' }}>{this.props.title}</h3>
                    <p style={{ textAlign: 'justify', margin: '0 10px' }}>
                        {this.props.text}
                    </p>
                    <br />
                </div>
            </div>
        )
    }
}

export default DescriptionCard
