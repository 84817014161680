import React, { Component } from 'react'
import AnimatedCard from './animatedcard'
import Breadcrumbs from '../breadcrumbs'
import SlickSlider from '../slickslider/'

class Us extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cards: [
                {
                    title: "Desarrollamos Estrategias",
                    text: `Y soluciones creativas de implementación dentro y fuera de una empresa, para aumentar la lealtad de todos sus actores y de esta manera, conseguir una imagen corporativa fuerte y coherente.`,
                    imgSrc: "assets/images/img/nosotros/Marketing-estrategico-1.jpg",
                    color: "#F39D2D"
                },
                {
                    title: "Capacidad de crear",
                    text: `Producir e implementar actividades de marketing, a través de herramientas como: Diseño, Publicidad, Marketing directo, BTL, Lanzamientos, Diseño y Construcción de stands y Eventos corporativos, contribuyendo a fortalecer la identidad de la marca.`,
                    imgSrc: "assets/images/img/nosotros/Escenografias-para-eventos-2.jpg",
                    color: "#2D91A3"
                },
                {
                    title: "Materializamos soluciones innovadoras",
                    text: `Integradas a las necesidades de su empresas . La creatividad y empatía son los motores desde la generación de ideas, hasta su ejecución, sin importar las dimensiones del proyecto o de tu empresa.`,
                    imgSrc: "assets/images/img/nosotros/Soluciones-innovadoras-1.jpg",
                    color: "#F50057"
                },
            ],
            slider: {
                slides: [
                    "assets/images/img/nosotros/Somos-un-gran-equipo.jpg",
                    "assets/images/img/nosotros/Trabajamos-coordinados.jpg",
                    "assets/images/img/nosotros/con-maxima-dedicacion.jpg",
                    "assets/images/img/nosotros/nos-comprometemos.jpg",
                    "assets/images/img/nosotros/supervisamos-cada-actividad.jpg",
                    "assets/images/img/nosotros/disfrutamos-lo-que-hacemos-1.jpg",
                    "assets/images/img/nosotros/disfrutamos-lo-que-hacemos-2.jpg",
                ],
                orthoCorrections: {
                    maxima: 'máxima',
                    dedicacion: 'dedicación',
                }
            }
        }
    }


    render() {
        return (
            <div>
                <section id="top">
                </section>
                <section id="middle">
                    <div className="middle_inner"></div>
                </section>
                <div className="wrap_headline">
                    <div className="headline">
                        <h1>{this.props.sectionName}</h1>
                    </div>
                </div>
                <Breadcrumbs sectionName={this.props.sectionName} />
                <div className="container">
                    <section id="middle">
                        <div className="middle_inner">
                            <section id="middle_content">
                                <div className="top_inner">
                                    <SlickSlider titleColor={this.props.themeColor} orthoCorrections={this.state.slider.orthoCorrections} images={this.state.slider.slides} />
                                </div>
                            </section>
                            <div className="entry">
                                <div className="cl"></div>
                                <h2>Creatividad sin Límites
                                </h2>
                                <p className="textocentrado">20 años de experiencia y un know-how que garantiza el éxito de la experiencia de tu marca.
                                </p>
                            </div>
                            <div className="divider"></div>
                            <h2>Nuestra tarea</h2>
                            <div id="cards-container">
                                {
                                    this.state.cards.map(content => {
                                        return (
                                            <AnimatedCard {...content} key={content.title} style={{ borderRadius: "15px" }} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

export default Us
