import React from 'react'
import { Snackbar, SnackbarContent, Button } from '@material-ui/core'

class MvSnackbar extends React.Component {
    defaultBgColors = {
        success: "#006600",
        warning: "#883300",
        error: "#660000"
    }
    defaultTimeout = 6000
    constructor(props) {
        super(props)
        this.handleSnackbarClose = this.handleSnackbarClose.bind(this)
    }

    handleSnackbarClose(event, reason) {
        if (reason === 'clickaway')
            return
        this.props.onHide()
    }

    render() {
        const bgColor = {
            info: (this.props.colors && this.props.colors.info) || this.defaultBgColors.success,
            success: (this.props.colors && this.props.colors.success) || this.defaultBgColors.success,
            warning: (this.props.colors && this.props.colors.warning) || this.defaultBgColors.warning,
            error: (this.props.colors && this.props.colors.error) || this.defaultBgColors.error,
        }[this.props.level || 'info']

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={this.props.show}
                autoHideDuration={this.props.timeout || this.defaultTimeout}
                onClose={this.handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
            >
                <SnackbarContent
                    message={
                        <span id='message-id'>
                            {stripHtml(this.props.message)}
                        </span>
                    }
                    action={[
                        <Button
                            key='undo'
                            size='large'
                            onClick={this.handleSnackbarClose}
                            style={{ color: "white", backgroundColor: "#ffffff16" }}
                        >
                            OK
                            </Button>,
                    ]}
                    style={{
                        backgroundColor: bgColor,
                        color: 'white',
                    }}
                />
            </Snackbar>
        )
    }
}

function stripHtml(html) {
    var tmp = document.createElement('DIV')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
}

export default MvSnackbar