import React from 'react'
import SectionContext from '../../sectioncontext'
import './style.css'
import styled from 'styled-components'

class SiteMap extends React.Component {
    render() {
        return (
            <SectionContext.Consumer>
                {({ chooseSection, currentSection, onSectionHovered }) => (
                    <div className='sitemap_container'>
                        {this.props.siteSections.map((section, index) => {
                            const StyledDiv = styled.div`
                                &:hover {
                                    background-color: ${this.props.siteSections[
                                        index
                                    ].themeColor}88;
                                }
                                &:active {
                                    background-color: ${this.props.siteSections[
                                        index
                                    ].themeColor}88;
                                }
                            `
                            return (
                                <StyledDiv
                                    className='item'
                                    key={`sitemap_${section.name}`}
                                    onClick={() => chooseSection(index)}
                                    onMouseEnter={() => onSectionHovered(index)}
                                    onMouseLeave={() => onSectionHovered(null)}
                                >
                                    <div>{section.name}</div>
                                </StyledDiv>
                            )
                        })}
                    </div>
                )}
            </SectionContext.Consumer>
        )
    }
}

export default SiteMap
