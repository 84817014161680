import React from 'react'
import Socicons from '../socicons/'
import Navbar from '../navbar'
import SectionContext from '../../sectioncontext'
import './style.css'

class Header extends React.Component {
    render() {
        return (
            <SectionContext.Consumer>
                {({ currentSection, chooseSection }) => (
                    <header className='header_container'>
                        <div className='header_inner-container'>
                            <div className='header_logo-container'>
                                <img
                                    src={require('assets/images/logo_pie_cropped_black.png')}
                                    alt=''
                                    onClick={() => chooseSection(0)}
                                />
                                <div>Productora de Marketing Corporativo</div>
                            </div>
                            <div className='header_socicons'>
                                <Socicons />
                            </div>
                        </div>
                        <Navbar siteSections={this.props.siteSections} />
                    </header>
                )}
            </SectionContext.Consumer>
        )
    }
}

export default Header
