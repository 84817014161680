import React from 'react'
import Card from '../card'
import { default as Description } from './description'
import SlickSlider from '../slickslider/'

class Start extends React.Component {
    state = {
        cards: [
            {
                text: `Desarrollamos estrategias creativas para campañas y piezas publicitarias, que nos
                    permite llegar al
                    público objetivo de manera totalmente alineada con los objetivos de la marca
                    Antes, durante y después de cada actividad, combinamos entornos online y
                    offline.`,
                title: "Marketing y Publicidad",
                icon: "assets/images/img/d-asesoria-marketing-empresas.png"
            },
            {
                text: `Eventos empresariales, corporativos, aniversarios, ceremonias y eventos sociales.
                    Eventos sociales de toda índole, celebraciones en general.`,
                title: "Eventos",
                icon: "assets/images/img/d-productora-eventos-corporativos.png"
            },
            {
                text: `Diseñamos piezas gráficas especialmente para comunicar las necesidades y
                    objetivos de cada marca.`,
                title: "Diseño y Producción",
                icon: "assets/images/img/d-diseno-produccion-impresion.png"
            }
        ],
        slider: {
            slides: [
                "assets/images/img/portada/organizacion-de-seminarios.jpg",
                "assets/images/img/portada/Organizacion-de-seminarios-1.jpg",
                "assets/images/img/diseno-grafico-y-publicidad.jpg",
                "assets/images/img/eventos/Celebraciones-de-Empresas.jpg",
                "assets/images/img/portada/Diseno-y-construccion-de-stands-1.jpg",
                "assets/images/img/portada/Team-building-para empresas-1.jpg",
                "assets/images/img/portada/Organizacion-de-eventos-para-empresas-2.jpg",
                "assets/images/img/portada/Escenografias-para-eventos-1.jpg",
            ],
            orthoCorrections: {
                organizacion: 'organización',
                diseno: 'diseño',
                grafico: 'gráfico',
                tematicas: 'temáticas',
                empreas: 'empresas',
                mineria: 'minería',
                escenpgrafias: 'escenografías',
                escenografias: 'escenografías',
                construccion: 'construcción',
                ambientacion: 'ambientación'
            }
        }
    }

    render() {
        return (
            <div>
                <section id="top">
                    <div className="top_inner">
                        <SlickSlider images={this.state.slider.slides} orthoCorrections={this.state.slider.orthoCorrections} titleColor={this.props.themeColor} />
                    </div>
                </section>
                <section id="middle">
                    <div className="middle_inner">
                        <section id="middle_content">
                            <div className="entry">
                                {
                                    this.state.cards.map((cardData, index) => {
                                        return <Card key={cardData.title} icon={cardData.icon} title={cardData.title} text={cardData.text} />
                                    })
                                }
                                <div className="cl"></div>
                                <Description />
                            </div>
                        </section>
                    </div>
                </section>
            </div>
        )
    }
}

export default Start
