import React from 'react';

class Card extends React.Component {
    render() {
        return (
            <div className="one_third aligncenter">
                <div className="featured_block">
                    <div className="cmsms_services_icon">
                        <img alt={this.props.title} className="aligncenter"
                            src={require(`../${this.props.icon}`)} />
                    </div>
                    <h2>{this.props.title}</h2>
                    <p>{this.props.text}</p>
                </div>
            </div>
        )
    }
}

export default Card