import React from 'react'
import SectionContext from '../../sectioncontext'
import styled from 'styled-components'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

class Navbar extends React.Component {
    state = {
        navbarCollapsed: true,
    }

    render() {
        return (
            <SectionContext.Consumer>
                {({ currentSection, chooseSection, onSectionHovered }) => (
                    <div>
                        {/* normal nav */}
                        <nav className='navbar_container'>
                            {this.props.siteSections.map((section, index) => {
                                const StyledDiv = styled.div`
                                    background-color: ${currentSection === index
                                        ? section.themeColor
                                        : null};
                                    transition: background-color 0.2s ease;

                                    &:hover {
                                        background-color: ${section.themeColor};
                                    }
                                `
                                return (
                                    <StyledDiv
                                        key={section.name}
                                        className={
                                            currentSection === index
                                                ? 'current_page_item'
                                                : 'drop'
                                        }
                                        onMouseEnter={() =>
                                            onSectionHovered(index)
                                        }
                                        onMouseLeave={() =>
                                            onSectionHovered(null)
                                        }
                                    >
                                        <div
                                            onClick={() => chooseSection(index)}
                                        >
                                            {section.name}
                                        </div>
                                    </StyledDiv>
                                )
                            })}
                        </nav>
                        {/* responsive nav */}
                        <div>
                            <div
                                className={`navbar_burger-container ${
                                    !this.state.navbarCollapsed
                                        ? 'expanded'
                                        : ''
                                }`}
                                onClick={() => {
                                    this.setState({
                                        navbarCollapsed: !this.state
                                            .navbarCollapsed,
                                    })
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faBars}
                                    size='3x'
                                ></FontAwesomeIcon>
                            </div>
                            <nav className='navbar_responsive-container'>
                                {this.props.siteSections.map(
                                    (section, index) => {
                                        return (
                                            <div
                                                key={`responsive_${section.name}`}
                                                onClick={() => {
                                                    this.setState({
                                                        navbarCollapsed: true,
                                                    })
                                                    chooseSection(index)
                                                }}
                                                className={`responsive_navbar-item ${
                                                    this.state.navbarCollapsed
                                                        ? 'collapsed'
                                                        : ''
                                                }`}
                                                style={{
                                                    borderLeft: `solid 8px ${section.themeColor}88`,
                                                }}
                                            >
                                                <span>{section.name}</span>
                                            </div>
                                        )
                                    },
                                )}
                            </nav>
                        </div>
                    </div>
                )}
            </SectionContext.Consumer>
        )
    }
}

export default Navbar
