import React, { Component } from 'react'
import Breadcrumbs from '../breadcrumbs'
import DescriptionCard from '../descriptioncard'
import SlickSlider from '../slickslider'

class Marketing extends Component {
    state = {
        cards: [
            {
                title: "Nos adaptamos a tu público objetivo",
                text: `Entender las motivaciones de tu target es esencial para comprender su manera de actuar y ofrecerle exactamente lo que busca, cuando lo necesita. Para lograrlo creamos perfiles del buyer persona para sacar insights que explotar.
                Además, buscamos nichos donde tus productos y servicios tengan aún más probabilidades de éxito.`
            },
            {
                title: "Estrategia de marketing personalizada",
                text: `Con toda la información en mano planteamos una estrategia. Unas líneas maestras que seguir para garantizar el éxito de la campaña.
                Nos valemos de los canales de tu marca pero, si es necesario, también creamos nuevos.
                Definimos estrategias de marketing a partir de los medios propios, pagados y ganados`
            }
        ],
        slider: {
            slides: [
                "assets/images/img/portada/Eventos-corporativos.jpg",
                "assets/images/img/Diseno-grafico-1.jpg",
                "assets/images/img/portada/Diseno-y-construccion-de-stands-1.jpg",
                "assets/images/img/galeria/Diseno-de-stands-5.jpg",
                "assets/images/img/organizacion-de-seminarios-1.jpg",
                "assets/images/img/nosotros/marketing-organizacional.jpg",
                "assets/images/img/galeria/Decoraciones-para-eventos- 5.jpg",
                "assets/images/img/galeria/Producciones-especiales-5.jpg",
                "assets/images/img/Soportes-publicitarios-1.jpg",
                "assets/images/img/talleres-motivacionales-para-empresas-1.jpg",
                "assets/images/img/eventos/Fiestas-para-empresas-1.jpg",
                "assets/images/img/galeria/regalos-corporativos.jpg",
            ],
            orthoCorrections: {
                diseno: 'diseño',
                grafico: 'gráfico',
                tematicas: 'temáticas',
                empreas: 'empresas',
                mineria: 'minería',
                escenpgrafias: 'escenografías',
                escenografias: 'escenografías',
                construccion: 'construcción',
                ambientacion: 'ambientación'
            }
        }
    }

    render() {
        return (
            <div>
                <section id="top">
                </section>
                <section id="middle">
                    <div className="middle_inner"></div>
                </section>
                <div className="wrap_headline">
                    <div className="headline">
                        <h1>{this.props.sectionName}</h1>
                    </div>
                </div>
                <Breadcrumbs sectionName={this.props.sectionName} />
                <div className="container">
                    <section id="middle">
                        <div className="middle_inner">
                            <section id="middle_content">
                                <br />
                                <h2>Marketing y Comunicación adaptados a cada necesidad</h2>
                                <div className="top_inner">
                                    <SlickSlider images={this.state.slider.slides}
                                        orthoCorrections={this.state.slider.orthoCorrections}
                                        titleColor={this.props.themeColor}
                                        titlePosition="DOWN" />
                                </div>
                            </section>
                            <div className="entry">
                                <section className="opened-article">
                                    <article className="portfolio hentry format-album project">
                                        <div className="entry-content project_content portfolio_container one_block">
                                            <div className="cl"></div>
                                            <p className="textocentrado">Planificación, creatividad y optimización para agregar valor a tu marca.
                                        </p>
                                        </div>
                                    </article>
                                    <div className="cl"></div>
                                    <div className="divider"><br /><br />

                                        <DescriptionCard {...this.state.cards[0]} />
                                        <DescriptionCard {...this.state.cards[1]} />

                                    </div>
                                </section>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

export default Marketing
