import React, { Component } from 'react'
import Breadcrumbs from '../breadcrumbs'
import SlickSlider from '../slickslider/'

class Gallery extends Component {
    state = {
        slider: {
            slides: [
                "assets/images/img/galeria/Seminarios-para-empresas.JPG",
                "assets/images/img/galeria/Organizacion-de-seminarios.jpg",
                "assets/images/img/galeria/diseno-de-flyers.jpg",
                "assets/images/img/galeria/Eventos-de-mineria-1.jpg",
                "assets/images/img/galeria/Productora-de-eventos-para-empresas-3.jpg",
                "assets/images/img/galeria/Talleres-motivacionales-para-empresas-2.jpg",
                "assets/images/img/galeria/Seminarios-para-empresas-1.jpg",
                "assets/images/img/galeria/Organizacion-de-seminarios-para-empresas-1.jpg",
                "assets/images/img/galeria/diseno-de-papeleria.jpg",
                "assets/images/img/galeria/Fiesta-de-empresa-1.jpg",
                "assets/images/img/galeria/Celebraciones-de-empresas-5.jpg",
                "assets/images/img/galeria/Fiestas-tematicas-para-empresas-1.jpg",
                "assets/images/img/galeria/Organizacion-de-eventos-para-empresas-2.jpg",
                "assets/images/img/galeria/Fiestas-dieciocheras-para-empresas-3.jpg",
                "assets/images/img/galeria/diseno-grafico-y-publicidad.jpg",
                "assets/images/img/galeria/Fiestas-para-empresas- 2.jpg",
                "assets/images/img/galeria/Eventos-de-mineria-2.jpg",
                "assets/images/img/galeria/Celebraciones-para-empresas- 1.JPG",
                "assets/images/img/galeria/Eventos-de-mineria- 1.jpg",
                "assets/images/img/galeria/diseno-material-POP.jpg",
                "assets/images/img/galeria/Productora-de-eventos-para-empresas-2.jpg",
                "assets/images/img/galeria/Eventos-para-empresas-de-mineria-2.jpg",
                "assets/images/img/galeria/Cordero-al-palo.jpg",
                "assets/images/img/galeria/Organizacion-de-fiestas-para-empresas-1.jpg",
                "assets/images/img/galeria/Evento-para-empresas- 4.jpg",
                "assets/images/img/galeria/Diseno-y-construccion-de-stands- 2.jpg",
                "assets/images/img/galeria/diseno-triptico.jpg",
                "assets/images/img/galeria/Diseno-y-construccion-de-stands-7.jpg",
                "assets/images/img/galeria/Diseno-y-construccion-de-stands-3.jpg",
                "assets/images/img/galeria/Diseno-de-stands-para-ferias-1.jpg",
                "assets/images/img/galeria/Diseno-de-Stands- 1.jpg",
                "assets/images/img/galeria/Diseno-de-stands- 3.jpg",
                "assets/images/img/galeria/Escenografias-para-eventos-1.jpg",
                "assets/images/img/galeria/Escenografias-para-eventos.jpg",
                "assets/images/img/galeria/Escenografias-para-eventos- 2.JPG",
                "assets/images/img/galeria/Organizacion-de-eventos-Antofagasta.jpg",
                "assets/images/img/galeria/Decoraciones-para-eventos- 5.jpg",
                "assets/images/img/galeria/Decoraciones-para-eventos- 2.jpg",
                "assets/images/img/galeria/Eventos-corporativos- 2.jpg",
                "assets/images/img/galeria/Eventos-corporativos-1.jpg",
                "assets/images/img/galeria/Eventos-para-empresas.JPG",
                "assets/images/img/galeria/Decoraciones-para-eventos.JPG",
                "assets/images/img/galeria/Eventos-de-mineria.jpg",
                "assets/images/img/galeria/Diseno-Grafico.jpg",
                "assets/images/img/galeria/Fiestas-tematicas-1.jpg",
            ],
            orthoCorrections: {
                organizacion: 'organización',
                diseno: 'diseño',
                tematicas: 'temáticas',
                empreas: 'empresas',
                mineria: 'minería',
                escenpgrafias: 'escenografías',
                escenografias: 'escenografías',
                construccion: 'construcción',
                ambientacion: 'ambientación',
            },
        },
    }

    render() {
        return (
            <div>
                <section id='top'></section>
                <section id='middle'>
                    <div className='middle_inner'></div>
                </section>
                <div className='wrap_headline'>
                    <div className='headline'>
                        <h1>{this.props.sectionName}</h1>
                    </div>
                </div>
                <Breadcrumbs sectionName={this.props.sectionName} />
                <div className='container'>
                    <section id='middle'>
                        <div className='middle_inner'>
                            <section id='middle_content'>
                                <br />
                                <h2>Organización, Coordinación y Ejecución</h2>
                                <div className='top_inner'>
                                    <SlickSlider
                                        images={this.state.slider.slides}
                                        orthoCorrections={
                                            this.state.slider.orthoCorrections
                                        }
                                        titlePosition='DOWN'
                                        titleColor={this.props.themeColor}
                                    />
                                </div>
                            </section>
                            <div className='entry'>
                                <section className='opened-article'>
                                    <article className='portfolio hentry format-album project'>
                                        <div className='entry-content project_content portfolio_container one_block'>
                                            <div className='cmsms_media'></div>
                                            <div className='cl'></div>
                                            <p className='textocentrado'>
                                                Organizamos tu evento de
                                                principio a fin
                                            </p>
                                        </div>
                                    </article>
                                    <div className='cl'></div>
                                    <div className='divider'>
                                        <br />
                                        <br />
                                        <br />
                                        <div className='one_third'>
                                            <p>
                                                Somos especialistas en la
                                                organización de{' '}
                                                <b>
                                                    eventos orientados a crear
                                                    una experiencia inolvidable
                                                </b>{' '}
                                                para el asistente su empresa,
                                                marca, servicio o producto.
                                            </p>
                                        </div>
                                        <div className='one_third'>
                                            <p>
                                                Cada evento es como un traje
                                                hecho a medida. Porque sabemos,
                                                tras 30 años de experiencia, que{' '}
                                                <b>
                                                    cada cliente requiere una
                                                    solución totalmente
                                                    personalizada
                                                </b>{' '}
                                                durante todo el proceso.
                                            </p>
                                        </div>
                                        <div className='one_third last'>
                                            <p>
                                                Ya sea para un evento
                                                corporativo, comercial o social,
                                                grande o pequeño,{' '}
                                                <b>
                                                    ponemos nuestra metodología
                                                    integral, pulida y
                                                    perfeccionada
                                                </b>
                                                a lo largo de los años, al
                                                servicio de nuestros clientes.
                                            </p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

export default Gallery
