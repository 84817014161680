import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import './style.css'

class Socicons extends React.Component {
    state = {
        networks: [
            {
                name: 'linkedin',
                href:
                    'https://www.linkedin.com/in/diana-díaz-bataller-695b002b/',
                icon: faLinkedin,
            },
        ],
    }
    render() {
        return (
            <div className='socicons_container'>
                {this.state.networks.map(network => {
                    return (
                        <a
                            key={network.href}
                            href={network.href}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <FontAwesomeIcon icon={network.icon} />
                        </a>
                    )
                })}
            </div>
        )
    }
}

export default Socicons
