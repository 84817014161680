import React from 'react'
import Header from './components/header/'
import Footer from './components/footer/'
import { default as StartView } from './components/start'
import { default as MarketingView } from './components/marketing'
import { default as UsView } from './components/us'
import { default as GalleryView } from './components/gallery'
import { default as ContactView } from './components/contact'
import SectionContext from './sectioncontext'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            siteSections: [
                {
                    name: 'Inicio',
                    component: StartView,
                    themeColor: '#49BFC2',
                },
                {
                    name: 'Nosotros',
                    component: UsView,
                    themeColor: '#2D91A3',
                },
                {
                    name: 'Marketing',
                    component: MarketingView,
                    themeColor: '#F39D2D',
                },
                {
                    name: 'Galería',
                    component: GalleryView,
                    themeColor: '#3E4FAD',
                },
                {
                    name: 'Contacto',
                    component: ContactView,
                    themeColor: '#F50057',
                },
            ],
            currentSection: 0,
            chooseSection: i => {
                if (i >= this.state.siteSections.length) {
                    console.debug('Wow, tried to go to section number ', i)
                    return
                }
                this.setState({
                    currentSection: i,
                    hoveredSection: null,
                })
                window.scrollTo(0, 0)
            },
            hoveredSection: null,
            onSectionHovered: sectionIndex => {
                this.setState({ hoveredSection: sectionIndex })
            },
        }
        this.siteTop = React.createRef()
    }

    render() {
        const currentSection = this.state.siteSections[
            this.state.currentSection
        ]

        const currenSectionAsList = [currentSection]
        const barColor = this.state.hoveredSection
            ? this.state.siteSections[this.state.hoveredSection].themeColor
            : currentSection.themeColor
        return (
            <SectionContext.Provider
                value={{
                    chooseSection: this.state.chooseSection,
                    currentSection: this.state.currentSection,
                    onSectionHovered: this.state.onSectionHovered,
                }}
            >
                <div className='App' ref={this.siteTop}>
                    <ThemeColorBar color={barColor} />
                    <section id='page'>
                        <Header siteSections={this.state.siteSections} />
                        <div id='navbar-background'></div>
                        <br />
                        <div className='container'>
                            <TransitionGroup>
                                {currenSectionAsList.map(thesection => {
                                    return (
                                        <CSSTransition
                                            key={`section_${thesection.name}`}
                                            classNames='sitesections-item'
                                            timeout={400}
                                            exit={false}
                                        >
                                            <thesection.component
                                                sectionName={thesection.name}
                                                themeColor={
                                                    thesection.themeColor
                                                }
                                                siteSections={
                                                    this.state.siteSections
                                                }
                                            />
                                        </CSSTransition>
                                    )
                                })}
                            </TransitionGroup>
                        </div>
                        <Footer siteSections={this.state.siteSections} />
                    </section>
                    <ThemeColorBar color={barColor} />
                </div>
            </SectionContext.Provider>
        )
    }
}

function ThemeColorBar(props) {
    return (
        <div
            className='theme-colorbar'
            style={{
                backgroundColor: props.color,
                width: '100%',
                overflow: 'hidden',
                height: '10px',
                transition: 'background-color 0.2s linear',
            }}
        />
    )
}

export default App
