import React, { Component } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

class SlickSlider extends Component {
    constructor(props) {
        super(props)
        this.showTitles = props.showTitles || true
        this.titlePosition = props.titlePosition || 'UP'
        this.titleColor = props.titleColor || '#fff'
        this.slickRef = React.createRef()
    }

    render() {
        const settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            centerMode: false,
            infinite: true,
            lazyLoad: 'ondemand', // progressive
            // Animation
            autoplay: true,
            autoplaySpeed: 3000,
            fade: true,
            cssEase: 'ease-in-out',
            speed: 500,
            pauseOnFocus: false,
            pauseOnHover: false,
            pauseOnDotsHover: true,
        }

        return (
            <div>
                <Slider ref={this.slickRef} {...settings}>
                    {this.props.images.map(image => {
                        return (
                            // <div key={image}>{image}</div>
                            <div key={image}>
                                <img
                                    src={require(`../../${image}`)}
                                    className='slider-img'
                                    alt={autogenerate_image_title(
                                        image,
                                        this.props.orthoCorrections,
                                    )}
                                />
                                {this.showTitles && (
                                    <div
                                        className={`slide-title 
                                        ${this.titlePosition === 'UP' &&
                                            'slide-title-up'} 
                                        ${this.titlePosition === 'DOWN' &&
                                            'slide-title-down'}`}
                                        style={{
                                            backgroundColor: this.titleColor,
                                        }}
                                    >
                                        {autogenerate_image_title(
                                            image,
                                            this.props.orthoCorrections,
                                        )}
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </Slider>
                <div className='slider-arrows'>
                    <div
                        className='slider-arrow arrow-left'
                        onClick={() => this.slickRef.current.slickPrev()}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </div>
                    <div
                        className='slider-arrow arrow-right'
                        onClick={() => this.slickRef.current.slickNext()}
                    >
                        <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                </div>
            </div>
        )
    }
}

var autogenerate_image_title = (str, corrections) => {
    const PREPOSITIONS = [
        'a',
        'ante',
        'bajo',
        'con',
        'contra',
        'de',
        'desde',
        'en',
        'entre',
        'hacia',
        'hasta',
        'para',
        'por',
        'según',
        'sin',
        'sobre',
        'tras',
        'mediante',
        'durante',
        'que',
        'y',
        'al',
    ]
    const ARTICLES = [
        'el',
        'la',
        'los',
        'las',
        'lo',
        'un',
        'una',
        'unos',
        'unas',
    ]

    // Normalize, remove path and extension
    str = str
        .split(/[\\/]/)
        .pop()
        .toLowerCase()
        .replace(/([-a-z]*)([0-9]*)(\.[a-z]{3,4})/g, '$1')
        .replace(/-/g, ' ')
        .trim()
    if (typeof corrections !== 'undefined' && corrections !== undefined)
        for (var bad_word in corrections) {
            const re = new RegExp(bad_word, 'g')
            str = str.replace(re, corrections[bad_word])
        }
    var final_str = ''
    str.split(' ').forEach((substr, index) => {
        final_str +=
            ' ' +
            (PREPOSITIONS.concat(...ARTICLES).includes(substr) && index !== 0
                ? substr
                : substr.charAt(0).toUpperCase() + substr.slice(1))
    })
    return final_str
}

export default SlickSlider
