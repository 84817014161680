import React, { Component } from 'react'
import SectionContext from '../sectioncontext'

class Breadcrumbs extends Component {
    render() {
        return (
            <SectionContext.Consumer>
                {({ currentSection, chooseSection }) => (
                    <div
                        className='wrap_cont_nav'
                        style={{ textAlign: 'center' }}
                    >
                        <div className='cont_nav'>
                            <span
                                onClick={() => chooseSection(0)}
                                style={{ color: '#2D91A3', cursor: 'pointer' }}
                            >
                                Inicio
                            </span>
                            &nbsp;/&nbsp;{this.props.sectionName}
                        </div>
                    </div>
                )}
            </SectionContext.Consumer>
        )
    }
}

export default Breadcrumbs
