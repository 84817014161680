import React from 'react'
import './marcavalor-cards.css'

class AnimatedCard extends React.Component {
    state = {
        borderRadius: this.props.borderRadius || '6px',
    }
    render() {
        return (
            <div
                className='card'
                onMouseEnter={e => e.currentTarget.classList.add('expanded')}
                onMouseLeave={e => e.currentTarget.classList.remove('expanded')}
            >
                <figure className='card-label'>
                    <img
                        src={require(`../../${this.props.imgSrc}`)}
                        alt={this.props.imgSrc}
                        className='fullwidth'
                        style={{ borderRadius: this.state.borderRadius }}
                    />
                </figure>
                <div
                    className='card-text'
                    style={{
                        backgroundColor: this.props.color,
                        borderRadius: `0 0 ${this.state.borderRadius} ${this.state.borderRadius}`,
                    }}
                >
                    <div className='text-content'>
                        <h6 className='card-title'>{this.props.title}</h6>
                        <div className='card-body-text'>{this.props.text}</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AnimatedCard
