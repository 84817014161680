import React from 'react'
import './style.css'
import {
    TextField,
    Button,
    withStyles,
    withTheme,
    InputAdornment,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import MvSnackbar from '../mvsnackbar'

const StyledButton = withStyles({
    root: {
        backgroundColor: '#2D91A3',
        color: 'white',
        height: '50px',
        margin: 'auto 5px',
        paddingLeft: '25px',
        paddingRight: '25px',
        transition: 'all .2s ease',
        '&:hover': {
            backgroundColor: '#F8F8F8',
            color: '#333',
        },
        '&:disabled': {
            backgroundColor: '#2D91A388',
        },
    },
})(Button)

const StyledTextField = withStyles({
    root: {
        '& input': {
            color: 'white',
        },
    },
})(TextField)

class ContactForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            mailSent: false,

            snackbarShow: false,
            snackbarMessage: '',
            snackbarLevel: 'info',
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.showSnackbar = this.showSnackbar.bind(this)
    }

    handleChange(event) {
        this.setState({
            email: event.currentTarget.value,
        })
    }

    isEmailValid() {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(this.state.email).toLowerCase())
    }

    handleSubmit(event) {
        event.preventDefault()

        axios
            .post(
                '/mailing.php',
                {
                    email: this.state.email,
                },
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                },
            )
            .then(
                response => {
                    console.log('Success: ', response)
                    this.showSnackbar('success', '¡Correo enviado! Te contactaremos cuanto antes')
                    this.setState({
                        mailSent: true
                    })
                },
                // error => {
                //     console.error('Error: ', error.response)
                //     this.snackbarError('Error: ' + error.response.data)
                // },
            )
            .catch(error => {
                console.warn('wat the fuck: ', error)
                this.showSnackbar('error', 'Oops, no funcionó. Mejor contáctanos directamente')
            })
    }

    showSnackbar(level, msg) {
        this.setState({
            snackbarMessage: msg,
            snackbarLevel: level,
            snackbarShow: true
        })
    }

    render() {
        return (
            <div className='footer_container'>
                <form className='footer_form' onSubmit={this.handleSubmit}>
                    <StyledTextField
                        id='email'
                        placeholder={this.state.mailSent ? '¡Gracias!' : 'Déjanos tu email aquí'}
                        variant='filled'
                        fullWidth
                        onChange={event => this.handleChange(event)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <FontAwesomeIcon
                                        style={{ color: '#eee' }}
                                        icon={faEnvelope}
                                    ></FontAwesomeIcon>
                                </InputAdornment>
                            ),
                        }}
                        error={
                            !this.isEmailValid() && this.state.email.length > 0
                        }
                        helperText={
                            !this.isEmailValid() &&
                            this.state.email.length > 0 &&
                            'El email no es válido'
                        }
                        disabled={this.state.mailSent}
                    />
                    <StyledButton disabled={!this.isEmailValid() || this.state.mailSent} type='submit'>
                        {this.state.mailSent ? "¡Enviado!" : "Enviar"}
                        {this.state.mailSent && (<FontAwesomeIcon
                            icon={
                                faPaperPlane
                            }
                            style={{ marginLeft: "10px" }}
                        ></FontAwesomeIcon>)}
                    </StyledButton>
                </form>
                <br />
                <p>
                    20 años creando soluciones de marketing y un know-how que
                    garantiza el éxito de la experiencia de tu marca
                </p>
                <MvSnackbar level={this.state.snackbarLevel} show={this.state.snackbarShow} message={this.state.snackbarMessage} timeout={6000}
                    onHide={() => { this.setState({ snackbarShow: false }) }}
                />
            </div>
        )
    }
}

export default withTheme(ContactForm)
