import React from 'react'
import DescriptionCard from '../descriptioncard'

class Description extends React.Component {
    state = {
        cards: [
            {
                title: "Conocemos a nuestros clientes",
                text: `Nos involucramos con nuestros clientes para conocer a cabalidad la marca,
                cómo respira, cuál es
                su target, cuáles son sus valores y objetivos.`
            },
            {
                title: "Análisis y propuestas",
                text: `Una vez conocidas las necesidades, creamos un equipo a medida de las mismas y
                comenzamos a
                trabajar para trasladar, con rapidez y eficacia, una propuesta que se ciña
                al presupuesto y a los
                objetivos marcados.`
            }
        ]
    }

    render() {
        return (
            <div className="fondotono">
                <h2>Somos una productora que va más allá</h2><br />

                <DescriptionCard {...this.state.cards[0]} />
                <DescriptionCard {...this.state.cards[1]} />

                <div className="cl"></div>
            </div>
        )
    }
}

export default Description