import React, { Component } from 'react'
import Breadcrumbs from '../breadcrumbs'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faEnvelope,
    faUser,
    faPhone,
    faComment,
    faPaperPlane
} from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import {
    TextField,
    Button,
    InputAdornment,
    withStyles,
} from '@material-ui/core'
import axios from 'axios'
import MvSnackbar from '../mvsnackbar'

class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            phone: '',
            message: '',
            nameChanged: false,
            emailChanged: false,
            phoneChanged: false,
            messageChanged: false,
            mailSent: false,

            snackbarMessage: '',
            snackbarShow: false,
            snackbarLevel: 'info'
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.showSnackbar = this.showSnackbar.bind(this)
    }

    showSnackbar(level, msg) {
        this.setState({
            snackbarMessage: msg,
            snackbarShow: true,
            snackbarLevel: level
        })
    }

    handleChange(event) {
        const targetId = event.currentTarget.id
        const targetValue = event.currentTarget.value

        this.setState({
            name: targetId === 'name' ? targetValue : this.state.name,
            email: targetId === 'email' ? targetValue : this.state.email,
            phone:
                targetId === 'phone'
                    ? filterPhoneCharacters(targetValue)
                    : this.state.phone,
            message: targetId === 'message' ? targetValue : this.state.message,
            nameChanged: targetId === 'name' || this.state.nameChanged,
            emailChanged: targetId === 'email' || this.state.emailChanged,
            phoneChanged: targetId === 'phone' || this.state.phoneChanged,
            messageChanged: targetId === 'message' || this.state.messageChanged,
        })
    }

    nameIsValid() {
        return this.state.name.length > 0
    }
    emailIsValid() {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(this.state.email).toLowerCase())
    }
    messageIsValid() {
        return this.state.message.length > 0
    }

    handleSubmit(event) {
        event.preventDefault()

        axios
            .post(
                '/mailing.php',
                {
                    name: this.state.name,
                    email: this.state.email,
                    phone: this.state.phone,
                    message: this.state.message,
                },
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                },
            )
            .then(
                response => {
                    console.log('Success: ', response)
                    this.showSnackbar('success', '¡Correo enviado! Te contactaremos cuanto antes')
                    this.setState({
                        mailSent: true
                    })
                },
                error => {
                    console.error('Error: ', error.response)
                    this.showSnackbar('error', `Ocurrió un error${error.response && error.response.data ? ': ' + error.response.data : ''}`)
                },
            )
            .catch(error => {
                console.warn('wat the fuck: ', error)
                this.showSnackbar('error', 'Oops, no funcionó. Mejor contáctanos directamente')
            })
    }

    render() {
        const SendButton = withStyles({
            root: {
                backgroundColor: 'white',
                border: 'solid 1px #f50057',
                color: '#f50057',
                fontWeight: 'bolder',
                transition: 'all .15s',
                '&:hover': {
                    backgroundColor: '#f50057',
                    color: 'white',
                },
            },
        })(Button)
        return (
            <div>
                <section id='top'></section>
                <section id='middle'>
                    <div className='middle_inner'></div>
                </section>
                <div className='wrap_headline'>
                    <div className='headline'>
                        <h1>{this.props.sectionName}</h1>
                    </div>
                </div>
                <Breadcrumbs sectionName={this.props.sectionName} />
                <div className='container_class'>
                    <section id='middle'>
                        <div className='middle_inner'>
                            <section id='middle_content'>
                                <div className='top_inner'>
                                    <div className='contact_wrap'>
                                        <div id='contact_form-container'>
                                            <form
                                                method='post'
                                                onSubmit={this.handleSubmit}
                                            >
                                                <TextField
                                                    id='name'
                                                    label='Nombre'
                                                    fullWidth
                                                    margin='normal'
                                                    onChange={this.handleChange}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='start'>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faUser
                                                                    }
                                                                ></FontAwesomeIcon>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    error={
                                                        this.state
                                                            .nameChanged &&
                                                        !this.nameIsValid()
                                                    }
                                                    helperText={
                                                        this.state
                                                            .nameChanged &&
                                                        !this.nameIsValid() &&
                                                        'Por favor ingresa tu nombre'
                                                    }
                                                    disabled={this.state.mailSent}
                                                />
                                                <TextField
                                                    id='email'
                                                    label='Email'
                                                    fullWidth
                                                    margin='normal'
                                                    onChange={this.handleChange}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='start'>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faEnvelope
                                                                    }
                                                                ></FontAwesomeIcon>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    error={
                                                        this.state
                                                            .emailChanged &&
                                                        !this.emailIsValid()
                                                    }
                                                    helperText={
                                                        this.state
                                                            .emailChanged &&
                                                        !this.emailIsValid() &&
                                                        'El correo no es válido'
                                                    }
                                                    disabled={this.state.mailSent}
                                                />
                                                <TextField
                                                    id='phone'
                                                    value={this.state.phone}
                                                    label='Teléfono'
                                                    fullWidth
                                                    margin='normal'
                                                    onChange={this.handleChange}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='start'>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faPhone
                                                                    }
                                                                ></FontAwesomeIcon>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    disabled={this.state.mailSent}
                                                />
                                                <TextField
                                                    id='message'
                                                    label='Mensaje'
                                                    fullWidth
                                                    margin='normal'
                                                    onChange={this.handleChange}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='start'>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faComment
                                                                    }
                                                                ></FontAwesomeIcon>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    multiline={true}
                                                    rows={4}
                                                    rowsMax={8}
                                                    error={
                                                        this.state
                                                            .messageChanged &&
                                                        !this.messageIsValid()
                                                    }
                                                    helperText={
                                                        this.state
                                                            .messageChanged &&
                                                        !this.messageIsValid() &&
                                                        'Cuéntanos un poco sobre tu proyecto'
                                                    }
                                                    disabled={this.state.mailSent}
                                                />

                                                <SendButton
                                                    variant='outlined'
                                                    className='contact_send_button'
                                                    type='submit'
                                                    disabled={
                                                        !this.nameIsValid() ||
                                                        !this.emailIsValid() ||
                                                        !this.messageIsValid() ||
                                                        this.state.mailSent
                                                    }
                                                >
                                                    {this.state.mailSent ? "¡Enviado!" : "Enviar"}
                                                    {this.state.mailSent && (<FontAwesomeIcon
                                                        icon={
                                                            faPaperPlane
                                                        }
                                                        style={{ marginLeft: "10px" }}
                                                    ></FontAwesomeIcon>)}
                                                </SendButton>
                                            </form>
                                            <div className='cmsms-form-builder'>
                                                <div className='widgetinfo'>
                                                    ¡Gracias! <br />
                                                    Su Mensaje ha sido enviado
                                                    exitosamente
                                                </div>
                                                <div className='widgeterror'>
                                                    Oops! <br />
                                                    Algo falló. Contáctanos
                                                    directamente
                                                </div>
                                            </div>
                                        </div>
                                        <div className='contact_address-container'>
                                            {/* Sección logo + datos */}
                                            <div>
                                                <div className=''>
                                                    <img
                                                        src={require('assets/images/logo_pie_cropped_black.png')}
                                                        width='auto'
                                                        height='50%'
                                                        alt='logo'
                                                    />
                                                    <hr />
                                                </div>
                                                <div className='contact_mailphone'>
                                                    <div className='mail'>
                                                        <h3>
                                                            <FontAwesomeIcon
                                                                className='contact_contact-icon'
                                                                icon={
                                                                    faEnvelope
                                                                }
                                                            />
                                                        </h3>
                                                        <h4>
                                                            contacto@marcavalor.cl
                                                        </h4>
                                                    </div>
                                                    <div className='phone'>
                                                        <h3>
                                                            <FontAwesomeIcon
                                                                className='contact_contact-icon'
                                                                icon={faPhone}
                                                            />
                                                        </h3>
                                                        <h4>
                                                            (+56) 9 9533 5262
                                                        </h4>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className='row'>
                                                    <h2
                                                        style={{
                                                            fontSize: '3rem',
                                                        }}
                                                    >
                                                        <a
                                                            href='https://www.linkedin.com/in/diana-díaz-bataller-695b002b/'
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faLinkedin
                                                                }
                                                            />
                                                        </a>
                                                    </h2>
                                                    <h4 className='col-md-12 text-center'>
                                                        Diana Díaz Bataller
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </section>
                            <div className='entry'>
                                <section className='opened-article'>
                                    <article className='portfolio hentry format-album project'>
                                        <div className='entry-content project_content portfolio_container one_block'>
                                            <div className='cl'></div>
                                            <h2>
                                                20 años generando experiencias
                                                de marca
                                            </h2>
                                            <p className='textocentrado'>
                                                Mantenemos el control de todos
                                                los aspectos de principio a fin:
                                                Desarrollo, producción, montaje,
                                                escenografía, iluminación y
                                                sonido, control de acceso, etc.
                                                Todo, para que cada producción
                                                sea una experiencia irrepetible.
                                            </p>
                                        </div>
                                    </article>
                                </section>
                            </div>
                        </div>
                    </section>
                </div>
                <MvSnackbar
                    onHide={() => { this.setState({ snackbarShow: false }) }}
                    show={this.state.snackbarShow}
                    message={this.state.snackbarMessage}
                    level={this.state.snackbarLevel} />
            </div>
        )
    }
}

function filterPhoneCharacters(phoneString) {
    return phoneString.replace(/([^+0-9-() ])/gi, '')
}

export default Contact
