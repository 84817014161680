import React from "react";
import SiteMap from "../sitemap/";
import ContactForm from "./contactform";

class Footer extends React.Component {
    render() {
        return (
            <section>
                <div className="footer_main">
                    <img
                        className="footer_logo"
                        src={require("assets/images/logo_pie_cropped.png")}
                        alt="logo"
                    />
                    <ContactForm />
                    <SiteMap siteSections={this.props.siteSections} />
                </div>
            </section>
        );
    }
}

export default Footer;
